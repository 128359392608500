import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  STATEMENT_MAIN_BODY,
  STATEMENT_SUB_TITLE,
} from '../../../../constants/printoutConstants';
import {
  StatementPrintoutTableData,
  StatementPrintoutOrderData,
} from '../../../../types';
import getDashboardStyle from '../../../../utils/getDashboardStyle';
import { ColumnsType } from 'antd/lib/table';
import { DATE_FORMAT } from '../../../../constants/systemConstants';
import moment from 'moment';

type StatementPrintoutTableProps = {
  data: StatementPrintoutOrderData;
};

const StatementPrintoutTable = ({ data }: StatementPrintoutTableProps) => {
  const { t } = useTranslation();
  const columns: ColumnsType<StatementPrintoutTableData> = [
    {
      title: t('order.printoutColumns.date'),
      key: 'date',
      dataIndex: 'date',
      width: 150,
      align: 'center',
      render: (text: any, record: StatementPrintoutTableData) => (
        <Typography.Text
          style={{ paddingLeft: 5, fontSize: STATEMENT_MAIN_BODY }}
        >
          {getDashboardStyle().isWholeSaleAppType
            ? record.orderShipping && record.orderShipping.expectShippingTime
              ? moment(record.orderShipping.expectShippingTime).format(
                  DATE_FORMAT
                )
              : record.date
            : record.date}
        </Typography.Text>
      ),
    },
    {
      title: t('order.printoutColumns.invoiceId'),
      align: 'center',
      key: 'serialNumber',
      dataIndex: 'serialNumber',
      width: 150,
      render: (text: any, record: StatementPrintoutTableData) => (
        <Typography.Text
          style={{ paddingLeft: 5, fontSize: STATEMENT_MAIN_BODY }}
        >
          {record.serialNumber}
        </Typography.Text>
      ),
    },
    {
      title: t('order.printoutColumns.invoiceAmount'),
      align: 'center',
      key: 'orderAmount',
      dataIndex: 'orderAmount',
      width: 150,
      render: (text: any, record: StatementPrintoutTableData) => (
        <Typography.Text
          style={{ paddingLeft: 5, fontSize: STATEMENT_MAIN_BODY }}
        >
          {record.isCreditMemo ? '' : record.orderAmount}
        </Typography.Text>
      ),
    },
    {
      title: t('order.printoutColumns.openAmount'),
      align: 'center',
      key: 'amount',
      dataIndex: 'amount',
      width: 150,
      render: (text: any, record: StatementPrintoutTableData) => (
        <Typography.Text
          style={{ paddingLeft: 5, fontSize: STATEMENT_MAIN_BODY }}
        >
          {record.isCreditMemo ? '-' + record.amount : record.amount}
        </Typography.Text>
      ),
    },
  ];

  return (
    <Table
      style={{ width: 575 }}
      className="tablePrintout"
      dataSource={data.statementOrders}
      rowKey={(data, index) => `${index}`}
      columns={columns}
      pagination={false}
      size="small"
      bordered={true}
      footer={() => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography.Text style={{ fontSize: STATEMENT_SUB_TITLE }}>
            {t('order.printoutColumns.amountDue')}
          </Typography.Text>
          <Typography.Text style={{ fontSize: STATEMENT_MAIN_BODY }}>
            {data.totalStatementAmount}
          </Typography.Text>
        </div>
      )}
    />
  );
};

export default StatementPrintoutTable;
