import { CloseCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import { actionPermissions } from '../../../constants/actionPermissions';
import { dashboardRoute } from '../../../constants/pathname';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  GENERAL_TIMEOUT,
} from '../../../constants/systemConstants';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useTab } from '../../../hooks/useTab';
import {
  FontSizeType,
  GoodData,
  OrderEnum,
  SalesAnalysisData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { getDataWithAuthToken } from '../../../utils/axiosRequest';
import { setFont } from '../../../utils/colComponents';
import getDashboardStyle from '../../../utils/getDashboardStyle';
import { hasPermission } from '../../../utils/hasPermission';
import {
  addCommas,
  addCommasPrice,
  mergeParamsToString,
} from '../../../utils/helperFunction';
import FiveHundred from '../../FiveHundred';
import FourZeroThree from '../../FourZeroThree';
import BrandDropdown from '../../goods/common/BrandDropdown';
import CategoryDropdown from '../../goods/common/CategoryDropdown';
import DepartmentDropdown from '../../goods/common/DepartmentDropdown';
import SelectGoodsModal from '../../goods/selectGoods/SelectGoodsModal';
import SellersDropdown from '../../sellers/SellersDropdown';
import TableFooterToolbar from '../../table/TableFooterToolbar';
import TableToolbar from '../../table/TableToolbar';
import UserRankDropdown from '../../users/common/UserRankDropdown';
import SupplierDropdown from '../../goods/common/SupplierDropdown';

type GoodsSalesProps = {
  isSeller: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const GoodsSales = ({ isSeller, isLoading, setIsLoading }: GoodsSalesProps) => {
  //General Components
  const [fourZeroThree, setFourZeroThree] = useState<boolean>(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { addTab } = useTab();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { RangePicker } = DatePicker;
  const [selectGoodsVisible, setSelectGoodsVisible] = useState<boolean>(false);
  //Data Components
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData>();
  const [good, setGood] = useState<GoodData>();
  const [data, setData] = useState<SalesAnalysisData[]>([]);
  const [orderEnumObj, setOrderEnumObj] = useState<OrderEnum>();
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const { t } = useTranslation();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  // Table Components
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const screens = Grid.useBreakpoint();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [totalItem, setTotalItem] = useState(0);
  const [sortValue, setSortValue] = useState<'SALES' | 'GOODS_NUM'>('SALES');
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SalesAnalysisData[]>([]);
  const columnKeys = [
    'minGoodsPrice',
    'maxGoodsPrice',
    'averageGoodsPrice',
    'totalGoodsWeight',
    'totalGoodsNum',
    'totalAmount',
    'goodsId',
    'fullGoodsName',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((key) => key !== 'goodsId')
  );

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('analysis/sales/goods/export/excel?', params);
  };

  const getFormParams = useCallback(() => {
    return {
      isSeller: getDashboardStyle().isSellerSwitch ? isSeller : undefined,
      page: page,
      size: pageSize,
      sortValue: sortValue,
      sellerId:
        getDashboardStyle().isSellerSwitch &&
        isSeller &&
        formRef.current &&
        form.getFieldValue('sellerId')
          ? form.getFieldValue('sellerId')
          : undefined,
      rankId:
        formRef.current && form.getFieldValue('rankId')
          ? form.getFieldValue('rankId')
          : undefined,
      departmentId:
        formRef.current && form.getFieldValue('departmentId')
          ? form.getFieldValue('departmentId')
          : undefined,
      supplierId:
        formRef.current && form.getFieldValue('supplierId')
          ? form.getFieldValue('supplierId')
          : undefined,
      catId:
        formRef.current && form.getFieldValue('catId')
          ? form.getFieldValue('catId').at(-1)
          : undefined,
      brandId:
        formRef.current && form.getFieldValue('brandId')
          ? form.getFieldValue('brandId')
          : undefined,
      startDate:
        formRef.current && form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[0]).format(DATE_FORMAT)
          : undefined,
      endDate:
        formRef.current && form.getFieldValue('date')
          ? moment(form.getFieldValue('date')[1]).format(DATE_FORMAT)
          : undefined,
      startExpectShippingTime:
        formRef.current && form.getFieldValue('expectShippingTime')
          ? moment(form.getFieldValue('expectShippingTime')[0]).format(
              DATE_FORMAT
            )
          : undefined,
      endExpectShippingTime:
        formRef.current && form.getFieldValue('expectShippingTime')
          ? moment(form.getFieldValue('expectShippingTime')[1]).format(
              DATE_FORMAT
            )
          : undefined,
      keyword:
        formRef.current && form.getFieldValue('keyword')
          ? form.getFieldValue('keyword')
          : undefined,
      shippingStatus:
        formRef.current && form.getFieldValue('shippingStatus')
          ? form.getFieldValue('shippingStatus').join()
          : undefined,
      goodsId:
        formRef.current && form.getFieldValue('goodsId')
          ? form.getFieldValue('goodsId')
          : undefined,
    };
  }, [page, pageSize, sortValue, form, isSeller]);

  const getData = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('analysis/sales/goods', {
      params: getFormParams(),
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setData(response.data.list);
          setTotalItem(response.data.totalItem);
        } else if (response && response.returnCode === 403) {
          setFourZeroThree(true);
        } else {
          setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [t, getFormParams, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (selectedGoodObjs) {
      form.setFieldsValue({ goodsId: selectedGoodObjs.goodsId });
      setGood(selectedGoodObjs);
      setSelectedGoodObjs(undefined);
    }
  }, [selectedGoodObjs, form]);

  const getOrderEnumList = useCallback(() => {
    getDataWithAuthToken('order/enum_list')
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            setOrderEnumObj(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  const columns: ColumnsType<SalesAnalysisData> = [
    {
      title: setFont(t('analysis.goodsSales.id'), fontSize),
      key: 'goodsId',
      dataIndex: 'goodsId',
      fixed: screens.lg ? 'left' : undefined,
      width: 80,
      render: (value) => (
        <Button
          style={{ padding: 0, fontSize: fontSize }}
          type="link"
          onClick={() => {
            addTab('', `${dashboardRoute.goods.detail}?good_id=${value}`);
          }}
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
        >
          {value}
        </Button>
      ),
    },
    {
      title: setFont(t('analysis.goodsSales.goodsName'), fontSize),
      key: 'fullGoodsName',
      dataIndex: 'fullGoodsName',
      render: (value, record) =>
        record &&
        hasPermission(actionPermissions.goodGroup.goodView) && (
          <Tooltip title={value}>
            <Button
              type="link"
              style={{
                padding: 0,
                textAlign: 'left',
                whiteSpace: 'normal',
                height: 'auto',
              }}
              disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
              onClick={() => {
                addTab(
                  '',
                  `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
                );
              }}
            >
              {value}
            </Button>
          </Tooltip>
        ),
    },
    {
      title: setFont(t('analysis.goodsSales.minGoodsPrice'), fontSize),
      key: 'minGoodsPrice',
      dataIndex: 'minGoodsPrice',
      width: 100,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(t('analysis.goodsSales.maxGoodsPrice'), fontSize),
      key: 'maxGoodsPrice',
      dataIndex: 'maxGoodsPrice',
      width: 100,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(t('analysis.goodsSales.averageGoodsPrice'), fontSize),
      key: 'averageGoodsPrice',
      dataIndex: 'averageGoodsPrice',
      width: 100,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
    {
      title: setFont(t('analysis.goodsSales.totalGoodsWeight'), fontSize),
      key: 'totalGoodsWeight',
      dataIndex: 'totalGoodsWeight',
      width: 160,
      sorter: true,
      render: (value) => setFont(addCommas(value), fontSize),
    },
    {
      title: setFont(t('analysis.goodsSales.totalGoodsNum'), fontSize),
      key: 'totalGoodsNum',
      dataIndex: 'totalGoodsNum',
      width: 160,
      sorter: true,
      render: (value) => setFont(addCommas(value), fontSize),
    },
    {
      title: setFont(t('analysis.goodsSales.totalAmount'), fontSize),
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 160,
      sorter: true,
      render: (value) => setFont(addCommasPrice(value), fontSize),
    },
  ];

  /**
   * @param pagination Current pagination state
   * @param filters    Current filter state
   * @param sorter     Current sorter state
   * @param extra      Current data source and action
   */
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SalesAnalysisData> | SorterResult<SalesAnalysisData>[],
    extra: {
      currentDataSource: SalesAnalysisData[];
      action: 'paginate' | 'sort' | 'filter';
    }
  ) => {
    if (extra.action === 'sort' && !Array.isArray(sorter)) {
      if (sorter.field === 'totalGoodsNum') {
        setSortValue('GOODS_NUM');
      } else if (sorter.field === 'totalAmount') {
        setSortValue('SALES');
      }
    }
  };
  useEffect(() => {
    if (!isSeller) form.resetFields(['sellerId']);
  }, [isSeller, form]);

  return fourZeroThree ? (
    <FourZeroThree />
  ) : fiveHundred ? (
    <FiveHundred />
  ) : (
    <>
      <Form //Datepicker, brandId, departmentId, supplierId, catId, 3 Buttons
        layout="vertical"
        form={form}
        ref={formRef}
      >
        <Row gutter={[16, 0]}>
          <Col span={24} md={12} lg={12} xl={4}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name={'date'}
              label={t('analysis.goodsSales.date')}
              // initialValue={
              //   [
              //     moment().subtract(8, 'days'),
              //     moment().subtract(1, 'days'),
              //   ]
              // }
            >
              <RangePicker
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                placeholder={[
                  t('analysis.goodsSales.startDate'),
                  t('analysis.goodsSales.endDate'),
                ]}
                ranges={{
                  [`${t('nivo.week')}`]: [
                    moment().startOf('isoWeek'),
                    moment().endOf('isoWeek'),
                  ],
                  [`${t('nivo.month')}`]: [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  [`${t('nivo.firstHalfYear')}`]: [
                    moment().startOf('year'),
                    moment().startOf('year').add(6, 'months'),
                  ],
                  [`${t('nivo.secondHalfYear')}`]: [
                    moment().startOf('year').add(6, 'months'),
                    moment().endOf('year'),
                  ],
                  [`${t('nivo.year')}`]: [
                    moment().startOf('year'),
                    moment().endOf('year'),
                  ],
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12} lg={12} xl={4}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name={'expectShippingTime'}
              label={t('analysis.goodsSales.expectShippingTime')}
              initialValue={[moment(), moment()]}
            >
              <RangePicker
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                placeholder={[
                  t('analysis.goodsSales.startDate'),
                  t('analysis.goodsSales.endDate'),
                ]}
                ranges={{
                  [`${t('nivo.week')}`]: [
                    moment().startOf('isoWeek'),
                    moment().endOf('isoWeek'),
                  ],
                  [`${t('nivo.month')}`]: [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  [`${t('nivo.firstHalfYear')}`]: [
                    moment().startOf('year'),
                    moment().startOf('year').add(6, 'months'),
                  ],
                  [`${t('nivo.secondHalfYear')}`]: [
                    moment().startOf('year').add(6, 'months'),
                    moment().endOf('year'),
                  ],
                  [`${t('nivo.year')}`]: [
                    moment().startOf('year'),
                    moment().endOf('year'),
                  ],
                }}
              />
            </Form.Item>
          </Col>

          {isSeller && (
            <Col span={24} md={12} lg={12} xl={3}>
              <Form.Item
                name="sellerId"
                label={t('analysis.goodsSales.seller')}
                style={{ marginBottom: 12 }}
              >
                <SellersDropdown />
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              label={t('analysis.goodsSales.userRank')}
              name="rankId"
              style={{ marginBottom: 12 }}
            >
              <UserRankDropdown />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              label={t('goods.goodsListColumns.goodsName')}
              name="keyword"
              style={{ marginBottom: 12 }}
            >
              <Input
                allowClear
                placeholder={t(
                  'searchPlaceholders.searchNameSnBarcodeKeywords'
                )}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              label={t('analysis.goodsSales.brand')}
              name="brandId"
              style={{ marginBottom: 12 }}
            >
              <BrandDropdown />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              label={t('analysis.goodsSales.department')}
              name="departmentId"
              style={{ marginBottom: 12 }}
            >
              <DepartmentDropdown />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              label={t('analysis.goodsSales.supplier')}
              name="supplierId"
              style={{ marginBottom: 12 }}
            >
              <SupplierDropdown />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              label={t('analysis.goodsSales.category')}
              name="catId"
              style={{ marginBottom: 12 }}
            >
              <CategoryDropdown onFocusFetch={true} />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={3}>
            <Form.Item
              name="shippingStatus"
              label={t('order.orderListColumns.advancedSearch.shippingStatus')}
              style={{ marginBottom: 12 }}
            >
              <Select
                placeholder={t('searchPlaceholders.selectStatus')}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                mode="multiple"
                maxTagCount="responsive"
                onFocus={() => {
                  if (
                    hasPermission(actionPermissions.orderGroup.orderView) &&
                    !orderEnumObj
                  )
                    getOrderEnumList();
                }}
              >
                {orderEnumObj &&
                  orderEnumObj['shippingStatus'].map((type) => (
                    <Select.Option key={type.code} value={type.code}>
                      {type.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={4}>
            <Form.Item
              name="goodsId"
              label={t('order.orderListColumns.advancedSearch.goods')}
              style={{ marginBottom: 12 }}
            >
              <Space>
                {good ? (
                  <Space>
                    <Typography.Text
                      ellipsis={{ tooltip: good.fullGoodsName }}
                      style={{ maxWidth: 100 }}
                    >
                      {good.fullGoodsName}
                    </Typography.Text>
                    <CloseCircleOutlined
                      onClick={() => {
                        form.resetFields(['goodsId']);
                        setGood(undefined);
                      }}
                    />
                  </Space>
                ) : (
                  <></>
                )}
                <Button
                  type="link"
                  onClick={() => setSelectGoodsVisible(true)}
                  style={{ padding: 0 }}
                >
                  {t('actionsColumn.selectGood')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ marginBottom: 12 }}>
              <Space>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        getData();
                      }, GENERAL_TIMEOUT)
                    );
                  }}
                >
                  {t('analysis.goodsSales.ok')}
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    setGood(undefined);
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        getData();
                      }, GENERAL_TIMEOUT)
                    );
                  }}
                >
                  {t('analysis.goodsSales.reset')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TableToolbar
        setFontSize={setFontSize}
        fontSize={fontSize}
        tableSize={tableSize}
        setTableSize={setTableSize}
        refresh={() => getData()}
        totalItems={totalItem}
        columns={columns}
        columnKeys={columnKeys}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        rows={data}
        exportConfig={
          hasPermission(actionPermissions.analysisGroup.salesStat)
            ? {
                fileName: 'GOODS_SALES',
                url: getExportUrl(getFormParams()),
              }
            : undefined
        }
      />
      <Table<SalesAnalysisData>
        onChange={onTableChange}
        dataSource={data}
        loading={isLoading}
        size={tableSize}
        columns={columns.filter((x) =>
          selectedColumns.includes(x.key?.toString() ?? '')
        )}
        components={vt}
        scroll={{ y: 600, x: 1200 }}
        rowKey={(sales) => `${sales.goodsId}`}
        rowSelection={{
          selectedRowKeys,
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: SalesAnalysisData[]
          ) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        pagination={{
          current: page,
          total: totalItem,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            t('general.paginationTotal', {
              start: range[0],
              end: range[1],
              total: total,
            }),
          size: 'small',
          defaultPageSize: pageSize,
          onChange: (p, pSize) => {
            setPage(p);
            setPageSize(pSize || pageSize);
            setSelectedRowKeys([]);
          },
        }}
      />
      <SelectGoodsModal
        firstLoad={false}
        newGoodObjs={selectedGoodObjs}
        setNewGoodObjs={setSelectedGoodObjs}
        visible={selectGoodsVisible}
        setVisible={setSelectGoodsVisible}
        sellerId={
          isSeller && form.getFieldValue('sellerId')
            ? Number(form.getFieldValue('sellerId'))
            : 0
        }
        multiSelectFeature={false}
      />
      {!!selectedRowKeys.length && (
        <TableFooterToolbar
          selectedRows={selectedRows}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns}
          funct={{
            exportConfig: hasPermission(
              actionPermissions.analysisGroup.salesStat
            )
              ? {
                  fileName: 'GOODS_SALES',
                  url: getExportUrl(getFormParams()),
                }
              : undefined,
          }}
        />
      )}
    </>
  );
};

export default GoodsSales;
