import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import GoodCommentsModal from '../../components/goods/GoodCommentsModal';
import SelectGoodsModal from '../../components/goods/selectGoods/SelectGoodsModal';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import UsersDropdown from '../../components/users/common/UsersDropdown';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1 } from '../../constants/color';
import { dashboardRoute } from '../../constants/pathname';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import { FontSizeType, GoodCommentData, GoodData } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';
import { useAppDispatch } from '../../app/hooks';
import { addPage } from '../../features/pageHistory/pageHistorySlice';

/**
 * Display Good's Comments List
 * /dashboard/goods/comments
 *
 * @prop route Route information from react-router-config
 */
const GoodCommentsList = () => {
  const queryString = window.location.search;
  const [goodId] = useState(new URLSearchParams(queryString).get('good_id'));
  //General Components
  const dispatch = useAppDispatch();
  const ellipsis = useState(true);
  const { addTab } = useTab();
  const [loading, setLoading] = useState(true);
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const isSubscribed = useRef(true);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [isSeller, setIsSeller] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [selectGoodsVisible, setSelectGoodsVisible] = useState<boolean>(false);
  //Data Components
  const [goodCommentData, setGoodCommentData] = useState<
    Array<GoodCommentData>
  >([]);
  const [goodCommentDetail, setGoodCommentDetail] = useState<GoodCommentData>();
  const [selectedGoodObjs, setSelectedGoodObjs] = useState<GoodData>();
  const [good, setGood] = useState<GoodData>();
  //Text Components
  const [keyword, setKeyword] = useState('');
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  //Table Components
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [selectedRows, setSelectedRows] = useState<GoodCommentData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const columnKeys = [
    'commentId',
    'userId',
    'orderId',
    'seller',
    'goods',
    'ipAddress',
    'content',
    'isUseful',
    'isEnabled',
    'isAdminReply',
    'addTime',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter(
      (key) =>
        key !== 'orderId' && key !== 'ipAddress' && key !== 'isAdminReply'
    )
  );
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);

  //as soon as the user selects a good from the SelectGoods modal, it triggers this useEffect
  useEffect(() => {
    if (selectedGoodObjs) {
      form.setFieldsValue({ goodsId: selectedGoodObjs.goodsId });
      setGood(selectedGoodObjs);
      setAdvance((prev) => ({
        ...prev,
        goodsId: selectedGoodObjs.goodsId.toString(),
      }));
      setSelectedGoodObjs(undefined);
    }
  }, [selectedGoodObjs, form]);

  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`goods/comment/list?`, {
      params: {
        keyword: keyword ? keyword : undefined,
        page: page,
        size: pageSize,
        isSeller: isSeller,
        userId:
          formRef.current && form.getFieldValue('userId')
            ? form.getFieldValue('userId')
            : undefined,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        goodsId: goodId
          ? goodId
          : formRef.current && form.getFieldValue('goodsId')
          ? form.getFieldValue('goodsId')
          : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodCommentData(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);

            if (goodId) {
              document.title = `${t('goods.goodsCommentsList')} ${
                response.data.data ? response.data.data.fullGoodsName : ''
              } - ${t(getDashboardStyle().title)} ${t('dashboard.title')}`;
              dispatch(
                addPage({
                  title: `${t('goods.goodsCommentsList')} ${
                    response.data.data ? response.data.data.fullGoodsName : ''
                  }`,
                  path: `${dashboardRoute.goods.comments}?good_id=${goodId}`,
                })
              );
            }

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, form, goodId, isSeller, keyword, dispatch]);

  const columns: ColumnsType<GoodCommentData> = [
    {
      title: setFont(t('goods.goodsCommentListColumns.commentId'), fontSize),
      dataIndex: 'commentId',
      key: 'commentId',
      fixed: screens.lg ? 'left' : undefined,
      width: 60,
      render: (text: string, record: GoodCommentData) => (
        <Button
          disabled={!hasPermission(actionPermissions.goodGroup.goodComments)}
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() => {
            getGoodCommentDetail(record.commentId);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a: GoodCommentData, b: GoodCommentData) =>
        compare(a.commentId, b.commentId),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.userId'), fontSize),
      dataIndex: 'userId',
      key: 'userId',
      width: 80,
      render: (text: string, record: GoodCommentData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.userGroup.userView)}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.users.detail}?user_id=${record.userId}`
            )
          }
        >
          {record.userId ? record.userId : ''}
        </Button>
      ),
      sorter: (a: GoodCommentData, b: GoodCommentData) =>
        compare(a.userId, b.userId),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.orderId'), fontSize),
      dataIndex: 'orderId',
      key: 'orderId',
      width: 100,
      render: (text: string, record: GoodCommentData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.order.detail}?order_id=${record.orderId}`
            )
          }
          disabled={!hasPermission(actionPermissions.orderGroup.orderView)}
        >
          {record.orderId ? record.orderId : ''}
        </Button>
      ),
      sorter: (a: GoodCommentData, b: GoodCommentData) =>
        compare(a.orderId, b.orderId),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.sellerInfo'), fontSize),
      dataIndex: 'seller',
      key: 'seller',
      width: 150,
      render: (text: string, record: GoodCommentData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={
            !hasPermission(actionPermissions.sellerGroup.sellerMerchants)
          }
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.sellers.detail}?seller_id=${record.seller.sellerId}`
            )
          }
        >
          <div style={{ wordBreak: 'break-word' }}>
            {record.seller && record.seller.shopName
              ? record.seller.shopName
              : ''}
          </div>
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.goodInfo'), fontSize),
      dataIndex: 'goods',
      key: 'goods',
      width: 150,
      render: (text: string, record: GoodCommentData) => (
        <Button
          type="link"
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          style={{
            padding: 0,
            textAlign: 'left',
            whiteSpace: 'normal',
            height: 'auto',
          }}
          onClick={() => {
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goods.goodsId}`
            );
          }}
        >
          {record.goods ? record.goods.goodsName : ''}
        </Button>
      ),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.ipAddress'), fontSize),
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      width: 150,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.content'), fontSize),
      dataIndex: 'content',
      key: 'content',
      render: (text: string) => (
        <Typography.Text
          style={
            ellipsis
              ? {
                  fontSize: fontSize,
                  width: 220,
                  overflow: 'auto',
                  maxHeight: 200,
                }
              : undefined
          }
        >
          {text}
        </Typography.Text>
      ),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.isUseful'), fontSize),
      dataIndex: 'isUseful',
      key: 'isUseful',
      width: 120,
      render: (value: boolean, record: GoodCommentData) => (
        <Switch
          disabled={!hasPermission(actionPermissions.goodGroup.goodComments)}
          checked={value}
          onChange={(checked) =>
            updateGoodComments('isUseful', checked, record.commentId)
          }
        />
      ),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.isEnabled'), fontSize),
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      width: 120,
      render: (value: boolean, record: GoodCommentData) => (
        <Switch
          disabled={!hasPermission(actionPermissions.goodGroup.goodComments)}
          checked={value}
          onChange={(checked) =>
            updateGoodComments('isEnabled', checked, record.commentId)
          }
        />
      ),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.isAdminReply'), fontSize),
      dataIndex: 'isAdminReply',
      key: 'isAdminReply',
      width: 70,
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
        ) : (
          <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
        ),
    },
    {
      title: setFont(t('goods.goodsCommentListColumns.addTime'), fontSize),
      dataIndex: 'addTime',
      key: 'addTime',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: GoodCommentData) => (
        <Space>
          <Button
            disabled={!hasPermission(actionPermissions.goodGroup.goodComments)}
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() => {
              getGoodCommentDetail(record.commentId);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={setFont(t('actionsColumn.deleteWarning'), fontSize)}
            onConfirm={() => {
              batchArchive([record.commentId]);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={!hasPermission(actionPermissions.goodGroup.goodComments)}
          >
            <Button
              disabled={
                !hasPermission(actionPermissions.goodGroup.goodComments)
              }
              danger
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const updateGoodComments = (
    key: string,
    value: string | number | boolean,
    id: number
  ) => {
    postDataWithAuthToken('goods/comment/edit', {
      commentId: id,
      [key]: value,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getData();
            alertMessage('success', t('goods.alerts.goodsCommentEdited'));
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  /**
   * Sends a request and sets the userData based on the typed keywords
   */
  const getGoodCommentDetail = (id: number) => {
    if (isSubscribed.current) setModalLoading(true);
    getDataWithAuthToken('goods/comment/detail', {
      params: {
        commentId: id,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodCommentDetail(response.data);
            setModalVisible(true);
          }
        } else
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );

        if (isSubscribed.current) setModalLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setModalLoading(false);
      });
  };

  /**
   * This popover displays, and handles the filters should the user need
   * It takes in inputs and formats it into parameters so that it can be used by getData
   */
  const advancedSearch = (
    <Popover
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      visible={showAdvancedSearch}
      overlayStyle={{ zIndex: 100 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {t('goods.goodsCommentListColumns.advancedSearch.title')}
          <CloseOutlined onClick={() => setShowAdvancedSearch(false)} />
        </div>
      }
      trigger="click"
      placement="bottomRight"
      content={
        <Form layout="vertical" form={form} ref={formRef}>
          <Form.Item
            name="userId"
            label={t('goods.goodsCommentListColumns.advancedSearch.userId')}
            style={{ marginBottom: 12 }}
          >
            <UsersDropdown
              onChange={(value: string) => {
                setAdvance((prev) => ({
                  ...prev,
                  userId: value,
                }));
              }}
            />
          </Form.Item>
          {isSeller && (
            <Form.Item
              name="sellerId"
              label={t('goods.goodsCommentListColumns.advancedSearch.sellerId')}
              style={{ marginBottom: 12 }}
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          {!goodId && (
            <Form.Item
              name="goodsId"
              label={t('order.orderListColumns.advancedSearch.goods')}
              style={{ marginBottom: 12 }}
            >
              <div>
                {good ? (
                  <>
                    <Typography.Text
                      ellipsis={{ tooltip: good.fullGoodsName }}
                      style={{ width: 120 }}
                    >
                      {good.fullGoodsName}
                    </Typography.Text>
                    <CloseCircleOutlined
                      style={{ padding: 10 }}
                      onClick={() => {
                        form.resetFields(['goodsId']);
                        setGood(undefined);
                        setAdvance((prev) => ({ ...prev, goodsId: '' }));
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
                {
                  <Button onClick={() => setSelectGoodsVisible(true)}>
                    {t('actionsColumn.selectGood')}
                  </Button>
                }
              </div>
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('goods.goodsCommentListColumns.advancedSearch.search')}
              </Button>
              <Button
                disabled={Object.values(advance).every((value) => !value)}
                onClick={() => {
                  form.resetFields();
                  setGood(undefined);
                  setAdvance({});
                  if (page !== 1) setPage(1);
                  else {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => getData(), GENERAL_TIMEOUT)
                    );
                  }
                }}
              >
                {t('goods.goodsCommentListColumns.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button
          icon={<FilterOutlined />}
          onClick={() =>
            setShowAdvancedSearch(showAdvancedSearch ? false : true)
          }
        >
          {t('goods.goodsCommentListColumns.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  const batchArchive = (id?: number[]) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('goods/comment/delete_batch', {
      commentIds: id ? id : selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('goods.alerts.goodsCommentDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Space>
              <Typography.Title level={3} style={{ fontWeight: 500 }}>
                {t('goods.goodsCommentsList')}
              </Typography.Title>
              {getDashboardStyle().isSellerSwitch && (
                <Switch
                  loading={loading}
                  checkedChildren={t('goods.goodsCommentListColumns.seller')}
                  unCheckedChildren={t('goods.goodsCommentListColumns.self')}
                  style={{ marginBottom: 12 }}
                  onChange={(checked) => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        setIsSeller(checked);
                        setPage(1);
                      }, EXTENDED_TIMEOUT)
                    );
                  }}
                />
              )}
            </Space>
            <TableToolbar
              fontSize={fontSize}
              setFontSize={setFontSize}
              tableSize={tableSize}
              setTableSize={setTableSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchGoodsComment')}
              advancedSearch={advancedSearch}
              rows={goodCommentData.map((goodComment) => ({
                ...goodComment,
                seller:
                  goodComment && goodComment.seller
                    ? goodComment.seller.sellerId
                    : '',
                goods:
                  goodComment && goodComment.goods
                    ? goodComment.goods.goodsId
                    : '',
              }))}
              exportConfig={{ fileName: 'GOOD_COMMENTS_LIST' }}
            />
            <Table<GoodCommentData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={goodCommentData}
              size={tableSize}
              loading={loading}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              rowKey={(goodComment) => goodComment.commentId}
              rowSelection={{
                selectedRowKeys,
                onChange: (
                  selectedRowKeys: React.Key[],
                  selectedRows: GoodCommentData[]
                ) => {
                  setSelectedRowKeys(selectedRowKeys);
                  setSelectedRows(selectedRows);
                },
              }}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                selectedRows={selectedRows}
                setSelectedRowKeys={setSelectedRowKeys}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
                funct={{
                  refresh: () => getData(),
                  exportConfig: { fileName: 'GOOD_COMMENTS_LIST' },
                  deleteFunc: hasPermission(
                    actionPermissions.goodGroup.goodComments
                  )
                    ? batchArchive
                    : undefined,
                }}
              />
            )}
          </Card>
          <GoodCommentsModal
            selectedGoodComment={goodCommentDetail}
            visible={modalVisible}
            setVisible={setModalVisible}
            loading={modalLoading}
            setLoading={setModalLoading}
            refresh={() => getData()}
          />
          <SelectGoodsModal
            firstLoad={false}
            newGoodObjs={selectedGoodObjs}
            setNewGoodObjs={setSelectedGoodObjs}
            visible={selectGoodsVisible}
            setVisible={setSelectGoodsVisible}
            sellerId={advance.sellerId ? parseInt(advance.sellerId) : 0}
            multiSelectFeature={false}
          />
        </>
      )}
    </Container>
  );
};
export default GoodCommentsList;
